@font-face {
  font-family: 'EverettRegular';
  src: url('./fonts/Everett-Regular-web.woff2') format('woff2'),
      url('./fonts/Everett-Regular-web.woff') format('woff'),
      url('./fonts/Everett-Regular-web.ttf') format('truetype');
}

@font-face {
  font-family: 'EverettMedium';
  src: url('./fonts/Everett-Medium-web.woff2') format('woff2'),
      url('./fonts/Everett-Medium-web.woff') format('woff'),
      url('./fonts/Everett-Medium-web.ttf') format('truetype');
}

html,
body {
  margin: 0;
  font-family: 'EverettRegular', sans-serif;
  background: #FAFAFA;
}

ul {
  color: red;
}

.verification-title {
  font-size: 24px;
  line-height: 34px;
  color: #000;
  font-weight: 400;
  margin-bottom: 15px;
  margin-top: 30px;
}

.logo-header {
  height: 40px;
}

.loan-wrap {
  display: flex;
  align-items: center;
}

.loan-column {
  padding-right: 60px;
  position: relative;
}

.loan-column h5 {
  font-size: 12px;
  line-height: 14px;
  color: #979797;
  margin: 0;
  padding-bottom: 5px;
  font-weight: 400;
}

.loan-column span {
  font-size: 14px;
  line-height: 18px;
  color: #2C2C2C;
  font-family: 'EverettRegular';
}

.loan-column:after {
  content: "";
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #EDEDEE;
}

.loan-column:last-child:after {
  display: none;
}

.main-title {
  font-weight: 400;
  color: #271A69;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 5px;
  border-bottom: 1px solid #271A69;
  margin-bottom: 18px;
}

.main-field {
  font-size: 12px;
  line-height: 14px;
  color: #2C2C2C;
  font-weight: 400;
}

.main-field p {
  font-size: 14px;
  line-height: 18px;
  font-family: 'EverettRegular';
  margin: 0;
  padding-top: 3px;
}

.data-source-wrap {
  margin: 30px 0;
}

.employer-wrap,
.borrower-wrap {
  margin-bottom: 30px;
}

.columns-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 17px;
}

.columns-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 10px;
}

.columns-5 {
  display: grid;
  grid-template-columns: 0.9fr 1fr 1fr 1fr 1fr;
  margin-bottom: 17px;
}

.columns-6 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #EDEDEE;
  margin-top: 12px;
  margin-bottom: 6px;
}

.footer {
  width: 100%;
  height: 1px;
  background-color: #EDEDEE;
  margin-top: 40px;
  margin-bottom: 80px;
}


.last-text {
  font-size: 12px;
  line-height: 14px;
  color: #2C2C2C;

  margin-top: 35px;
}

.last-text span {
  font-family: 'EverettRegular';
}


footer {
  background-color: #F9F9F9;
  text-align: center;
  margin-top: 35px;
  margin-bottom: -10px;

}

footer p {
  font-size: 12px;
  line-height: 14px;
  padding: 12px 0;
}

footer span {
  font-family: 'EverettRegular';
}


.header-2 {
  background-color: #271A69;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.header-2 h3 {
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  text-align: center;

}

.header-3 h3 {
  font-size: 16px;
  margin: 0;
  font-weight: 400;
  padding-top: 20px;
  padding-bottom: 6px;
}

.logo-2 {
  height: 27px;
}


.window-header-div {
  background-color: #271A69;
  padding: 12px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.window-header {
  margin: 0px;
  color: #fff;
  text-align: center;
  font-size: 16px;
}


.window-container {
  border: 1px solid #271A69;
  border-radius: 16px;
  width: 400px;
  margin: auto;
  margin-top: 160px;
}

.window-inner-container {
  margin: 24px;
}

.window-inner-container p {
  font-size: 14px;
  text-align: center;
}

.verification-items {
  padding: 20px 0 2px 0;

}

.verification-items span {
  display: block;
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 2px;
}

.section-2 {
  margin-bottom: 30px;
}

.main-title-bg {
  background-color: #f3f3f3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.main-title-bg h3 {
  font-size: 16px;
  padding: 12px 20px 8px 20px;
}

.main-title-bg h2 {
  font-size: 18px;
  padding: 16px 20px;
}

.fields-wrap {
  padding: 4px 20px 4px 0px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 20px;
}

span {
  font-size: 14px;
  line-height: 8px;
}

.section-2.borrower-2 {
  padding-bottom: 20px;
}


.section-2.employer-2 {
  padding-bottom: 20px;
}

.section-2.income-2 {
  padding-bottom: 20px;
}

.up-footer {
  margin: 0 20px;
  padding-bottom: 20px;
}

.sub-footer-2 {
  background-color: #271A69;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
}

.sub-footer-2 h5 {
  margin: 0;
  color: #ffffff;
  font-size: 11px;
  line-height: 14px;
}

.footer-2 p {
  font-size: 12px;
  line-height: 14px;
}


a:link {
  color: #271A69;
}

a:hover {
  color: #777;
}

input[type=text],
input[type=password],
select {
  width: 100%;
  padding: 8px 10px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #271A69;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 12px;
}

#loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
  margin: auto;
  margin-top: 200px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  visibility: hidden;
}

@-webkit-keyframes spin {
  0% {
      -webkit-transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

.center-block {
  margin: auto;
  display: block;
}

.vertical-margin {
  margin: 40px 0;
}

.horizontal-margin {
  margin: 0px 20px;
}

.margin {
  margin: 20px;
}

#back-div {
  padding: 30px;
  margin-left: 10px;
}





#topBar {
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0px;

  width: 100%;
  height: 24px;
  top: 0px;

  /* Foundation/Pure Black */
  background: #000000;
  position: fixed;
  z-index: 1;
}

.topBarText {
  position: static;
  height: 16px;
  left: 16px;
  top: 0px;

  /* Caption/Medium */
  font-family: EverettRegular;
  font-size: 14px;
  line-height: 130%;
  /* identical to box height, or 16px */

  /* Brand/Sun */
  color: #F3FC65;


  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
}

.backText {
  position: static;
  height: 24px;
  left: 16px;
  top: 0px;

  /* Caption/Medium */
  font-family: EverettRegular;
  font-size: 14px;
  line-height: 130%;
  font-weight: 700;
  /* identical to box height, or 16px */

  color: #5E84FF;


  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
}

#topLeftDiv {
  /* Left */

  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: static;
  width: 150px;
  height: 16px;
  left: 24px;
  top: 24px;


  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
  cursor: pointer;
}

#topCenterDiv {
  /* Center */

  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  position: static;
  width: 138.92px;
  height: 20px;
  left: 650.54px;
  top: 22px;


  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 24px 0px 0px;
}

#topRightDiv {
  /* Right */

  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;

  position: static;
  width: 150px;
  height: 24px;
  left: 1266px;
  top: 20px;


  /* Inside Auto Layout */
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 10px;
}

.icon {
  /* ic_back */

  position: static;
  width: 12px;
  height: 12px;
  left: 0px;
  top: 2px;


  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 2px;
}


.medium_icon {
  /* ic_back */

  position: static;
  width: 16px;
  height: 16px;
  left: 0px;
  top: 2px;


  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.right_icon {
  /* ic_help */

  position: static;
  width: 24px;
  height: 24px;
  left: 12px;
  top: 0px;


  /* Inside Auto Layout */
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 16px 0px 6px;
}


#logo {
  /* Logo */

  position: static;
  width: 138.92px;
  height: 20px;
  left: 0px;
  top: calc(50% - 20px/2);


  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 12px;
}

#verificationContainer {
  /* Frame 2 */
  margin-left: auto;
  margin-right: auto;

  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  height: auto;
  left: 88px;
  top: 310px;


  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

#verificationContent {
  /* Welcome List Item */

  /* Auto Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 24px;

  position: static;
  width: 560px;
  height: auto;
  left: 0px;
  top: 48px;

  /* Foundation/White */
  background: #FFFFFF;
  /* Foundation/Silver */
  border: 1px solid #EDEDEE;
  box-sizing: border-box;
  border-radius: 12px;

  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;

}


#verificationHeader {
  position: static;
  height: 46px;
  left: -100px;
  top: calc(50% - 46px/2);

  /* H2/Medium */

  font-size: 44px;
  line-height: 46px;
  /* identical to box height, or 105% */


  /* Foundation/Pure Black */

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 30%;

}

.verificationHeaderHolder{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 200px;
  margin-top: 50px;

  /* Foundation/White */

  background: #FFFFFF;
  box-shadow: inset 0px -1px 0px #EDEDEE;
}

.verificationContentHeader {
  position: static;
  width: auto;
  height: 46px;
  left: 0px;
  top: calc(50% - 36px/2 - 90px);

  /* H3/Medium */
  font-family: EverettRegular;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 112% */

  /* Foundation/Pure Black */
  display: inline-block;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  color: #5E84FF;
}

.verificationContentHeaderStartedPage {
  position: static;
  width: auto;
  height: 46px;
  left: 0px;
  top: calc(50% - 36px/2 - 90px);

  /* H3/Medium */
  font-family: EverettRegular;
  font-size: 24px;
  line-height: 36px;
  /* identical to box height, or 112% */

  /* Foundation/Pure Black */
  display: inline-block;

  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
  color: #000000;
}

.verificationContentHeaderResult{
  position: static;
  width: 228px;
  height: 28px;
  left: 0px;
  top: calc(50% - 28px/2 - 156px);

  /* H4/Medium */

  font-family: EverettMedium;
  font-size: 24px;
  line-height: 28px;
  /* identical to box height, or 117% */


  /* Brand/Sky */

  color: #5E84FF;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
}


.verificationHeaderContainer {
  /* Welcome List Item */

  position: static;
  width: 688px;
  height: auto;
  left: 0px;
  top: 48px;
}

.verificationResultContent {
  /* Welcome List Item */

  /* Auto Layout */
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 12px;

  position: static;
  width: 688px;
  height: auto;
  left: 0px;
  top: 48px;

  /* Foundation/White */
  background: #FFFFFF;
  /* Foundation/Silver */
  border: 1px solid #EDEDEE;
  box-sizing: border-box;
  border-radius: 12px;

  /* Inside Auto Layout */
  flex: none;
  flex-grow: 0;
  margin: 0px;
  padding: 24px
}

.transactionListItem {
  /* Welcome List Item */

  /* Auto Layout */
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 12px;

  position: static;
  width: 688px;
  height: auto;
  left: 0px;
  top: 24px;

  /* Foundation/White */
  background: #FFFFFF;
  /* Foundation/Silver */
  border: 1px solid #EDEDEE;
  box-sizing: border-box;
  border-radius: 12px;

  /* Inside Auto Layout */
  flex: none;
  flex-grow: 0;
  margin: 4px;
  padding: 24px
}

.transactionListItemText{
  font-family: EverettMedium;
}

.transactionListItemFailText{
  font-family: EverettMedium;
  color: #D5320F;
  line-height: 16px;
}

.transactionStatusText{
  font-family: EverettMedium;
  text-align: end;
}

.verificationResultContent-start {
  /* Welcome List Item */

  /* Auto Layout */
  display: flex;
  justify-content: flex-end;
  padding: 12px;

  position: static;
  width: 50%;
  height: auto;
  left: 0px;
  top: 48px;

  /* Foundation/White */
  /* Foundation/Silver */
  box-sizing: border-box;
  border-radius: 12px;

  /* Inside Auto Layout */
  flex: none;
  flex-grow: 0;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 50px;
  padding: 24px
}


.verificationLabel {
  position: static;
  width: auto;
  height: 20px;
  left: 0px;
  top: 0px;

  /* Large Body/Medium */
  font-family: EverettMedium;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;

  /* Foundation/Pure Black */
  color: #000000;


  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.verificationValue {
  position: static;
  width: 89px;
  height: 20px;
  left: 81px;
  top: 0px;

  /* Large Body/Regular */
  font-family: EverettRegular;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */
  text-align: center;

  /* Foundation/Pure Black */
  color: #000000;


  /* Inside Auto Layout */
  flex: none;
  order: 1;
  flex-grow: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.verificationValueDiv {
  margin-left: 8px;
}


.verification-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 14px;
}

.verification-row-1-20 {
  display: grid;
  grid-template-columns: 1fr 20fr;
  margin-top: 14px;
}

.verification-row-1-8-8 {
  display: grid;
  grid-template-columns: 1fr 8fr 8fr;
  margin-top: 14px;
}

.verification-row-2-6-1 {
  display: grid;
  grid-template-columns: 2fr 6fr 1fr;
}

.verification-block-1-10 {
  display: grid;
  grid-template-columns: 1fr 10fr;
  vertical-align: top;
}

.verification-block-1-10 {
  display: grid;
  grid-template-columns: 1fr 10fr;
  vertical-align: top;
}

.verification-row-1-1-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 14px;
}

.verification-row-1 {
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 14px;
}

.verification-block-1-2 {
  display: grid;
  grid-template-columns: 1fr 2fr;
  vertical-align: top;
}

.verification-block-1-4 {
  display: grid;
  grid-template-columns: 1fr 4fr;
  vertical-align: top;
}

.verification-block-1-5 {
  display: grid;
  grid-template-columns: 1fr 5fr;
  vertical-align: top;
}

.verification-block-1-6 {
  display: grid;
  grid-template-columns: 1fr 6fr;
  vertical-align: top;
}

.verification-block-1-7 {
  display: grid;
  grid-template-columns: 1fr 7fr;
  vertical-align: top;
}

.verification-block-2-7 {
  display: grid;
  grid-template-columns: 2fr 7fr;
  vertical-align: top;
}

.verification-block-2-3 {
  display: grid;
  grid-template-columns: 2fr 3fr;
  vertical-align: top;
}

.verification-block-2-5 {
  display: grid;
  grid-template-columns: 2fr 5fr;
  vertical-align: top;
}

.verification-block-1-3 {
  display: grid;
  grid-template-columns: 1fr 3fr;
  vertical-align: top;
}

.verification-block-1-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  vertical-align: top;
}

.verification-block-3 {
  display: inline-block;
  width: 32%;
  vertical-align: top;
}





#bottomRightImage {
  position: absolute;
  bottom: 0px;
  right: 0px;
}



.sectionContainer {
  margin-left: auto;
  margin-right: auto;

  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 688px;
  height: auto;
  left: 88px;
  top: 310px;


  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 70px
}

.sectionContainer.verification{
  margin-top: 12px;
}

.sectionContainer-start {
  margin-left: auto;
  margin-right: auto;
  /* Auto Layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  max-width: 800px;
  height: auto;
  left: 88px;
  top: 310px;
  min-width: 800px;
  width: 75%;


  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-top: 70px
}

.pageContainer {
  background-color: #FAFAFA;
}

.pageContainer.result{
  margin-top: 200px;
}

.leftMargin {
  margin-left: 10px
}

#buttons_holder{
    display: flex;
    flex-direction: row;
    margin-top: 0px;
}

#verificationButton {
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px 12px 16px;

  width: auto;
  height: 44px;

  /* Brand/Sky */
  background: #5E84FF;
  border-radius: 6px;
  border: 0px solid #271A69;

  /* Inside Auto Layout */
  flex: none;
  order: 2;
  flex-grow: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 48px;
  cursor: pointer;
  font-size: 16px;
  font-family: 'EverettRegular';
  color: white;
}

#partnersButton {
  /* Auto Layout */
  justify-content: left;
  padding: 12px 16px 12px 16px;

  width: auto;
  height: 44px;

  /* Brand/Sky */
  background: #5E84FF;
  border-radius: 6px;
  border: 0px solid #271A69;

  /* Inside Auto Layout */
  cursor: pointer;
  font-size: 16px;
  font-family: 'EverettRegular';
  color: white;

  position: absolute;
  right: 20px;
  top: 70px;
}

#verificationButton:disabled,
#verificationButton[disabled]{
  opacity: 0.3;
}

#cancelButton{
    /* Auto Layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 16px 12px 16px;

    width: 180px;
    height: 44px;

    /* Brand/Sky */
    border-radius: 6px;
    border: 1px solid #2c2c2c;
    background-color: #2c2c2c;

    /* Inside Auto Layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: auto;
    cursor: pointer;
    font-size: 16px;
    font-family: 'EverettRegular';
    color: white;
}
#loadMoreButton{
    /* Auto Layout */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*padding: 4px 8px 4px 8px;*/

    width: 120px;
    height: 24px;

    /* Brand/Sky */
    border-radius: 6px;
    border: 1px solid #2c2c2c;
    background-color: transparent;

    /* Inside Auto Layout */
    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 12px auto 32px auto;
    cursor: pointer;
    font-size: 12px;
    font-family: 'EverettRegular';
    color: #2c2c2c;
}

.downloadButton {
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px 6px 16px;

  width: auto;
  height: 33px;

  /* Brand/Sky */
  background: #5E84FF;
  border-radius: 6px;
  border: 0px solid #271A69;
  float: right;

  /* Inside Auto Layout */
  flex: none;
  order: 2;
  flex-grow: 0;

  margin: auto;
  cursor: pointer;
  font-size: 16px;
  font-family: 'EverettRegular';
  color: white;
  display: flex;
}


.checkbox-borrower {

  position: relative;
  height: 18px;
  width: 18px;

  /* Brand/Sky */
  background: #5E84FF;
  margin-bottom: 2px;
  border: #BEBEBE;
  border-width: 1px;
}
.checkbox-employment{
  height: 18px;
  width: 18px;
}

.borrower_coborrower{
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  margin-bottom: 20px;
  /*background-color: #ddd;*/
  /*border-radius: 16px;*/
  /*border: 1px solid #bbb;*/
  width: 100%;
}

.bJSZJD{
      -webkit-box-align: center;
    align-items: center;
    color: rgb(151, 151, 151);
    display: flex;
    -webkit-box-flex: 1;
    flex-grow: 1;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
}

.dnaugp {
    font-size: 12px;
    /* line-height: 130%; */
    font-weight: 400;
}

.partnersSelect{
  width: 500px;
  height: 44px;
  background: #FFFFFF;
  /* Foundation/Silver */

  font-family: EverettRegular;
  border: 1px solid #EDEDEE;
  box-sizing: border-box;
  border-radius: 6px;
  cursor: pointer;
}

.selectLabel{
  margin-bottom: 0px;
}

.partnersSelectOption{
  text-transform: uppercase;
}

.partnerSelectHolder{
  /* Right */


  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0px;

  position: static;
  width: 800px;
  height: 44px;
  left: 0px;
  top: 32px;


  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 12px 0px;
}

.partnerInfoButton{
  /* Button */
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;

  position: static;
  width: 44px;
  height: 44px;
  left: 0px;
  top: 0px;

  /* Foundation/Silver */
  background: transparent;
  border: 1px solid #EDEDEE;
  box-sizing: border-box;
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 12px;
}

.transactionHistoryButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;

  position: static;
  width: 156px;
  height: 44px;
  left: 356px;
  top: 0px;

  /* Foundation/Silver */
  background: transparent;
  border: 1px solid #EDEDEE;
  box-sizing: border-box;
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 12px;
}

.transactionHistoryLabel{
  position: static;
  width: 150px;
  height: 16px;
  left: 24px;
  top: 14px;

  /* Small/Medium */

  font-family: EverettMedium;
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: 500;
  /* identical to box height, or 114% */


  /* Brand/Sky */

  color: #5E84FF;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.transactionHistoryButtonInResult {
  /* Auto Layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 8px 6px 8px;

  width: auto;
  height: 33px;

  /* Brand/Sky */
  background: #F7F7FC;
  border-radius: 6px;
  border-width: 1px;
  border-color: #5E84FF;
  cursor: pointer;
  float: right;

  /* Inside Auto Layout */
  flex: none;
  order: 2;
  flex-grow: 0;

  margin: auto;
  cursor: pointer;
  font-size: 16px;
  font-family: 'EverettMedium';
  color: white;
  display: flex;
}


.partnerManageButton{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;

  position: static;
  width: 156px;
  height: 44px;
  left: 356px;
  top: 0px;

  /* Foundation/Silver */
  background: transparent;
  border: 1px solid #EDEDEE;
  box-sizing: border-box;
  border-radius: 6px;

  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0px 12px;
}

.partnerManageLabel{
  position: static;
  width: 108px;
  height: 16px;
  left: 24px;
  top: 14px;

  /* Small/Medium */

  font-family: EverettMedium;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  /* Brand/Sky */
  color: #5E84FF;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.labelHolder{
  position: static;
  width: 223px;
  height: 20px;
  left: 0px;
  top: 0px;

  /* Large Body/Regular */

  font-size: 16px;
  line-height: 20px;

  /* identical to box height, or 125% */

  text-align: center;

  /* Foundation/Pure Black */

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 12px 0px;
}

.infoIconHolder{
  position: static;
  width: 24px;
  height: 24px;
  left: 10px;
  top: 10px;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.infoIcon{
  height: 24px;
  width: 24px;
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
}


.BorrowerInfoHolder{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 800px;
  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 40px;
}

.borrower{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 24px;

  position: static;
  width: 800px;
  left: 0px;
  top: 32px;

  /* Foundation/White */

  background: #FFFFFF;
  /* Foundation/Silver */

  border: 1px solid #EDEDEE;
  box-sizing: border-box;
  border-radius: 12px;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.borrower.missingInfo{
  flex-direction: column;

}

.borrower.top{
  border-bottom: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.borrower.coborrower{
  border-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.borrowerCheckbox{
  position: static;
  width: 24px;
  height: 24px;
  left: 24px;
  top: 24px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.borrowerData{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 416px;
  left: 72px;
  top: 24px;


  /* Inside auto layout */
  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 1;
  margin: 0px 24px;
}

.borrowerName{
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 416px;
  height: 20px;
  left: 0px;
  top: 0px;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.borrowerCompanyTitle{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 416px;
  left: 0px;
  top: 32px;


  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 12px 0px;
}

.borrowerCompany{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 320px;
  left: 0px;
  top: 0px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin-right: 10px;
}

.borrowerTitle{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 300px;
  left: 0px;
  top: 0px;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 1;
}

.borrowerAddress{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 416px;
  height: 20px;
  left: 0px;
  top: 32px;


  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
}

.borrowerBottom{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 416px;

  /* Inside auto layout */

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin: 12px 0px;
}

.borrowerDivider{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: static;
  width: 416px;
  height: 8px;
  left: 0px;
  top: 0px;
  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.borrowerDividerRectangle{
  position: static;
width: 416px;
height: 1px;
left: calc(50% - 416px/2);
top: calc(50% - 1px/2);

/* Foundation/Silver */

background: #EDEDEE;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
margin: 10px 0px;
}

.borrowerCheckboxes{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 416px;
  left: 0px;
  top: 20px;


  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 12px 0px 0px 0px;
}

.borrowerEmployer{
  position: static;
  width: 71px;
  height: 20px;
  left: 0px;
  top: 0px;

  /* Large Body/Medium */

  font-family: EverettMedium;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: center;

  /* Foundation/Pure Black */

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.boldedLabel{
  position: static;
  width: 101px;
  height: 20px;
  left: 0px;
  top: 0px;

  /* Large Body/Medium */

  font-family: EverettMedium;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: left;

  /* Foundation/Pure Black */

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 10px;
}

.boldedLabel.title{
  width: 35px;
}

.coborrowerBoldedLabel{
  width: 100px;
}

.simplelabel.missing{
  color: #E91D36;
}

/*.verificationContainer{*/
/*  background-color: rgb(250, 250, 250);*/
/*  background-position: 100% 100%;*/
/*  background-repeat: no-repeat;*/
/*  background-image: url("images/stair_bg.svg");*/
/*  background-size: auto;*/
/*  height: 100%;*/
/*  width: 100%;*/
/*  overflow-y: auto;*/
/*}*/

.missing_info{
  padding-bottom: 5px;
}

.nextArrowHolder{
  position: static;
  width: 24px;
  height: 24px;
  left: 134px;
  top: 10px;


  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  margin-left: 8px;
  margin-top: 8px;
}

.nextArrowIcon{
  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 8.33%;
}

.pdfWindowContainer {
  /* Frame 2 */
  margin-left: auto;
  margin-right: auto;

  /* Auto Layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: absolute;
  vbackground-color: rgba(255,255,255,0.5);;


  /* Inside Auto Layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.ReportSelectorArea{
  /* Auto layout */

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  position: static;
  width: 888px;
  height: 100px;
  left: 0px;
  top: 0px;


  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
}

.ReportSelector{
  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;

  position: static;
  /*width: 888px;*/
  height: 24px;
  left: 0px;
  top: 64px;


  /* Inside auto layout */

  flex: none;
  order: 2;
  align-self: stretch;
  flex-grow: 0;
  margin: 4px 0px;
}

.ReportSelectorText{
  position: static;
  /*width: 400px;*/
  height: 20px;
  left: 16px;
  top: 0px;

  /* Large Body/Regular */

  font-family: 'EverettRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 125% */

  text-align: left;

  /* Foundation/Pure Black */

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}

.ReportSelectorTitleText{
  position: static;
  /*width: 400px;*/
  height: 20px;
  left: 16px;
  top: 0px;

  /* Large Body/Regular */

  font-family: 'EverettRegular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  text-align: left;

  /* Foundation/Pure Black */

  color: #000000;


  /* Inside auto layout */

  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 4px 4px;
}

.ReportSelectorInput{
  position: static;
  width: 16px;
  height: 16px;
  left: 0px;
  top: 0px;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}

.ManualVerificationHolder{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.pdfDisclaimer{
  margin-top: 3px;
  margin-right: 10px;
}

.pdfName{
  margin-top: 3px;
  margin-left: 10px;
}